import React, { useEffect, useRef } from 'react';
import styled from '@emotion/styled';

import Divider from '@components/_atoms/Divider';
import Typography from '@components/_atoms/Typography';
import Icon from '@components/_atoms/Icon';

// colors
import { gray, primary } from '@styles/Colors';

// constants
import { Shadow } from '@styles/Shadow';
import { Radius } from '@styles/Radius';
import { ButtonColor, TypoVariant } from '@constants/atoms';
import { MOBILE_MODE, TABLET_MODE } from '@constants/size';
import { Keyframes } from '@constants/keyframes';
import Button from '@components/_atoms/Button';
import { useTranslation } from 'next-i18next';
import ButtonCode from '@constants/log/buttonCode';

type Props = Styleable & {
  readonly isOpen: boolean;
  readonly width?: number;
  readonly title: string | React.ReactNode;
  readonly subTitle?: string;
  readonly variant?: TypoVariant;
  readonly subVariant?: TypoVariant;
  readonly children: React.ReactNode;
  //readonly modalRef?: any;
  readonly gap?: number;
  readonly padding?: number;
  readonly okText?: string;
  readonly cancelText?: string;
  readonly disabled?: boolean;
  readonly ok?: () => void;
  readonly onClose: () => void;
  readonly isLoading?: boolean;
  readonly isDivider?: boolean;
  readonly isCancelButton?: boolean;
};

const ModalContainer = styled.div`
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  animation: ${Keyframes.slowOpacityWithScale} 0.15s forwards;
`;

const ModalWrapper = styled.div<{ width?: number; gap: number }>`
  display: flex;
  flex-direction: column;
  width: ${(props) => `${props.width}px`};
  background: #fff;
  box-shadow: ${Shadow.MEDIUM};
  border-radius: ${Radius.MEDIUM};
  gap: ${(props) => `${props.gap}px`};
  overflow: auto;

  @media ${MOBILE_MODE}, ${TABLET_MODE} {
    width: 100%;
    height: 100%;
  }
`;

const ModalHeader = styled.div<{ padding: number }>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${({ padding }) => `
    padding-top: ${padding}px;
    padding-left: ${padding}px;
    padding-right: ${padding}px;
  `};
`;

const ModalTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

const ModalContent = styled.div<{ padding: number }>`
  ${({ padding }) => `
    padding-left: ${padding}px;
    padding-right: ${padding}px;
`};
  overflow: auto;
  max-height: 880px;
`;

const FooterButtonSection = styled.div<{ padding: number }>`
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: center;

  ${({ padding }) => `
    padding-left: ${padding}px;
    padding-right: ${padding}px;
    padding-bottom: ${padding}px;
`};
`;

export default function BasicModal({
  children,
  isOpen,
  width,
  title,
  subTitle,
  variant = TypoVariant.SH3,
  subVariant = TypoVariant.B4,
  gap = 24,
  padding = 16,
  okText,
  cancelText,
  disabled = false,
  ok,
  onClose,
  isLoading,
  isDivider = true,
  isCancelButton = true,
}: Props) {
  const modalWrapperRef = useRef<HTMLDivElement>(null);
  const curModalRef = useRef<HTMLDivElement>(null);

  const { i18n } = useTranslation();
  const ko = i18n.language === 'ko';
  //esc 키 모달닫기
  const escFunction = useRef((event: KeyboardEvent) => {});
  useEffect(() => {
    escFunction.current = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };
    const onEscKeyDown = escFunction.current;
    window.addEventListener('keydown', onEscKeyDown);

    return () => {
      window.removeEventListener('keydown', onEscKeyDown);
    };
  }, []);

  // 모달이 열릴 때 body 스크롤 방지
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    // cleanup function
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  return isOpen ? (
    <ModalContainer ref={modalWrapperRef}>
      <ModalWrapper ref={curModalRef} width={width} gap={gap}>
        <ModalHeader padding={padding}>
          {typeof title === 'string' ? (
            subTitle ? (
              <ModalTitleWrapper>
                <Typography variant={variant} color={primary.gray}>
                  {title}
                </Typography>
                <Typography variant={subVariant} color={gray.gray7}>
                  {subTitle}
                </Typography>
              </ModalTitleWrapper>
            ) : (
              <Typography variant={variant} color={primary.gray}>
                {title}
              </Typography>
            )
          ) : (
            title
          )}
          <Icon name="close" stroke={primary.gray} onClick={onClose} style={{ marginBottom: 'auto' }} />
        </ModalHeader>
        {isDivider && <Divider />}
        <ModalContent padding={padding}>{children}</ModalContent>
        {isDivider && <Divider />}
        <FooterButtonSection padding={padding}>
          {isCancelButton && (
            <Button
              width="100%"
              text={cancelText || (ko ? '닫기' : 'Cancel')}
              color={ButtonColor.OutlinedBlack}
              onClick={onClose}
              steelbosoId={ButtonCode.BTN_CANCEL}
            />
          )}
          {ok && (
            <Button
              width="100%"
              text={okText || (ko ? '확인' : 'OK')}
              onClick={ok}
              disabled={disabled}
              steelbosoId={ButtonCode.BTN_MODAL_CONFIRM}
              isLoading={isLoading}
            />
          )}
        </FooterButtonSection>
      </ModalWrapper>
    </ModalContainer>
  ) : (
    <></>
  );
}
